<template>
  <div class="bg_F8F8F8">
    <Header :activeName="activeName" />
    <el-carousel class="banner" arrow="never" indicator-position="none">
      <el-carousel-item v-for="(item, index) in imgList" :key="index">
        <img :src="item.img" class="banner_img" />
      </el-carousel-item>
    </el-carousel>
    <div class=" flex justify-center">
      <div class="fixed_width home_title mt_20 mb_20">
        首页>新闻资讯>资讯详情
      </div>
    </div>
    <div class="bg_FFFFFF flex justify-center">
      <div class="fixed_width">
        <el-row class="pb_60">
          <el-col :span="18">
            <div class="mr_170">
              <div class="text-center news_title">{{ newsInfo.title }}</div>
              <div class="flex center mt_30 fz_16 fc_8C8C8C">
                <div class="flex center mr_50" v-if="newsInfo.author">{{ newsInfo.author }}</div>
                <div class="flex center">
                  <img
                    src="../../assets/images/news/clock.png"
                    class="clock_image mr_10"
                  />
                  {{ newsInfo.createTime }}
                </div>
                <div class="flex center">
                  <img
                    src="../../assets/images/news/eyes.png"
                    class="eyes_image mr_10"
                  />
                  {{ newsInfo.look }}
                </div>
              </div>
              <div class="line mt_30"></div>
              <div class="mt_30 detail_content" v-html="newsInfo.content"></div>

              <div class="mt_60 pb_60">
                <el-row>
                  <el-col :span="12">
                    <div class="fz_18 fc_5B5B5B">上一篇：</div>
                    <div
                      class="mt_20 fz_20 fc_5B5B5B font_blod cursor-pointer"
                      v-if="moreNews.news_pre"
                      @click="handleToDetail(moreNews.news_pre.id)"
                    >
                      {{ moreNews.news_pre.title }}
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="fz_18 fc_5B5B5B">下一篇：</div>
                    <div
                      class="mt_20 fz_20 fc_5B5B5B font_blod cursor-pointer"
                      v-if="moreNews.news_next"
                      @click="handleToDetail(moreNews.news_next.id)"
                    >
                      {{ moreNews.news_next.title }}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="hot_title">热门资讯</div>
            <div
              v-for="(item, index) in hotNewsList"
              :key="index"
              @click="handleToDetail(item.id)"
              class="cursor-pointer"
            >
              <div class="line mt_25"></div>
              <div class="mt_30 fz_24 fc_5B5B5B text-overflow">
                {{ item.title }}
              </div>
              <!-- <div
                class="mt_20 fz_16 fc_5B5B5B text-overflow"
                v-html="item.content"
              ></div> -->
              <div
                class="mt_20 fz_16 fc_5B5B5B text-overflow"
              >{{item.seoDescription}}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <Bottom />
  </div>
</template>

<script>
import Header from '@/components/header/index';
import Bottom from '@/components/bottom/index';
import { getNewsById, getHotNews, getNewsByIdMore, getBanner } from '@/api/home';
export default {
  components: {
    Header,
    Bottom
  },
  data() {
    return {
      newsInfo: '',
      activeName: '新闻资讯',
      hotNewsList: [],
      moreNews: [],
      imgList: [],
    };
  },
  mounted() {
    this.getList();
    this.getBannerList()
  },
  methods: {
    getBannerList() {
      getBanner({type: '3'}).then(res => {
        this.imgList = res.data
      })
    },
    handleToDetail(id) {
      // this.$router.push({
      //   path: './newsDetail',
      //   query: { id: id }
      // });
      this.$router.replace({
        query: { id: id }
      });
      this.getList();
    },
    getList() {
      getNewsById(this.$route.query.id).then((res) => {
        this.newsInfo = res.data;
      });
      getHotNews().then((res) => {
        this.hotNewsList = res.data;
      });
      getNewsByIdMore(this.$route.query.id).then((res) => {
        this.moreNews = res.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.home_title {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #111c2f;
}
.mr_170 {
  margin-right: 170px;
}
.news_title {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #5b5b5b;
  margin-top: 58px;
}
.fc_8C8C8C {
  color: #8c8c8c;
}
.clock_image {
  width: 14px;
  height: 14px;
  margin-left: 80px;
}
.eyes_image {
  width: 18px;
  height: 14px;
  margin-left: 80px;
}
.line {
  height: 2px;
  background: #e5e5e5;
}
.hot_title {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #5b5b5b;
  margin-top: 58px;
}
.mt_25 {
  margin-top: 25px;
}
.fc_5B5B5B {
  color: #5b5b5b;
}
.pb_60 {
  padding-bottom: 60px;
}
.text-overflow {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.banner {
  margin-top: 69px;
  height: 411px;
  width: 100%;
  overflow: hidden;
}
.banner_img {
  overflow: hidden;
  width: 1920px;
  height: 411px;
  position: relative;
  left: 50%;
  margin-left: -960px;
}
::v-deep .el-carousel__container {
  height: 411px;
  z-index: 1;
}

::v-deep .el-carousel__button {
  width: 14px;
  height: 14px;
  border: 2px solid #dcdcdc;
  border-radius: 50%;
}

::v-deep .is-active .el-carousel__button {
  // 指示器激活按钮
  border: 2px solid #2c8eff;
  width: 14px;
  height: 14px;
}

::v-deep .el-carousel__indicator--horizontal {
  padding: 30px 15px;
}
.detail_content {
  ::v-deep {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>